export const firebaseConfig = {
  apiKey: "AIzaSyCHSV6O5Bx4FjoNRJseRexyCT1KubjBdwk",
  authDomain: "gantwerker.firebaseapp.com",
  databaseURL: "https://gantwerker.firebaseio.com",
  projectId: "gantwerker",
  storageBucket: "gantwerker.appspot.com",
  messagingSenderId: "1060091350720",
  appId: "1:1060091350720:web:d4ba666981b8fdd9f4e725"
};

export const vapidKey = `BGJuBcZUe-Qtno_iX-eAyHEq6zvGl-tV2pnOXbD3dWJBaCNlgaT0cgQHoXqwr-ftdn-74QbCU7xNs0x7kg0nNts`;

// ...

const region = 'us-central1';

export function getCloudFunctionUrl(cfName) {
  if (process.env.REACT_APP_TOKEN_EMULATOR === 'true') {
    return `http://localhost:5001/${firebaseConfig.projectId}/${region}/${cfName}`;
  } 

  return `https://${region}-${firebaseConfig.projectId}.cloudfunctions.net/${cfName}`;
}